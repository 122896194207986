import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import _ from 'lodash';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import usePromiseSource from '@vl/hooks/usePromiseSource';
import useRoute from '@vl/hooks/useGbRouteDe';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchProfile: false }))),

            getAccountZone: hook(() => {
              const route = useRoute();
              const routeParams = route.getParams();
              const account_profile_id = _.get(routeParams, 'account_profile.id', '');
              const data = usePromiseSource(
                async () => {
                  try {
                    if (!account_profile_id) return null;
                    const client = await getClient();
                    const data = await client.request(
                      gql`
                        query GetAccountZone($id: uuid!) {
                          b2b_account_profile_by_pk(id: $id) {
                            holiday_zone
                          }
                        }
                      `,
                      { id: account_profile_id }
                    );
                    return data;
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                [account_profile_id]
              );

              return _.get(data, 'data.b2b_account_profile_by_pk', '');
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
