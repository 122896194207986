import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Menu, Badge } from 'antd';
import gstyles from '@vl/gstyles';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import styled from 'styled-components';
import Link from '@uz/unitz-components-web/Link';

const CustomMenu = styled.div`
  .ant-menu {
    background: transparent;
    border-right: 0;
  }
  .ant-menu > .ant-menu-item-selected,
  .ant-menu > .ant-menu-item-active {
    background-color: #e6ebf0;
    border-radius: 8px;
  }
  .ant-menu: not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: ${gstyles.colors.brandb500};
  }
`;

const CustomBadge = styled.div`
  .ant-badge-count {
    box-shadow: none;
    .ant-scroll-number-only {
      .ant-scroll-number-only-unit {
        color: ${gstyles.colors.white500};
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
`;

const Index = () => {
  return (
    <DIV className="ZoomToolOrganaztionMenu">
      <CustomMenu className="h-screen">
        <Menu
          mode="inline"
          inlineIndent={24}
          defaultSelectedKeys={ctx.get('advisorProfileMenu.activeMenu')}
          onSelect={(selectedItemObject) => {
            ctx.apply('advisorProfileMenu.onSelectMenu', selectedItemObject);
          }}
        >
          {_.map(ctx.get('advisorProfileMenu.menuData', []), (menu) => {
            if (!_.get(menu, 'subMenu.length')) {
              return (
                <Menu.Item key={menu.id}>
                  <Link
                    to={ctx.apply('routeStore.toLocale', menu.href)}
                    className="flex flex-row justify-between items-center text-base font-normal
                    cursor-pointer py-2 text-main"
                  >
                    <div className="flex items-center">
                      <div>
                        {gstyles.icons({
                          className: 'mr-4',
                          name: menu.icon,
                          size: 24,
                          fill:
                            ctx.get('advisorProfileMenu.activeMenu') === menu.id
                              ? gstyles.colors.white500
                              : gstyles.colors.sub,
                        })}
                      </div>
                      <div
                        className={`${
                          ctx.get('advisorProfileMenu.activeMenu') === menu.id ? 'text-white500' : 'text-sub'
                        }  text-sm`}
                      >
                        {menu.text}
                      </div>
                    </div>
                    {!!_.get(menu, 'badge') && (
                      <CustomBadge>
                        <Badge count={_.get(menu, 'count', 0)} />
                      </CustomBadge>
                    )}
                  </Link>
                </Menu.Item>
              );
            }
            return (
              <Menu.SubMenu
                key={menu.id}
                title={
                  <div
                    className="flex flex-row text-base font-normal
                  cursor-pointer py-2 text-main"
                  >
                    {gstyles.icons({
                      className: 'mr-4',
                      name: menu.icon,
                      size: 24,
                      fill: gstyles.colors.sub,
                    })}
                    {menu.text}
                  </div>
                }
              >
                {menu.subMenu.map((subMenu) => (
                  <Menu.Item key={subMenu.id}>
                    <Link
                      to={`${subMenu.href}`}
                      className="flex flex-row text-base font-normal
                        cursor-pointer py-2 text-main"
                    >
                      {gstyles.icons({
                        className: 'mr-4',
                        name: subMenu.icon,
                        size: 24,
                        fill: gstyles.colors.sub,
                      })}
                      {subMenu.text}
                    </Link>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            );
          })}
        </Menu>
      </CustomMenu>
    </DIV>
  );
};

export default displayName(Index);
