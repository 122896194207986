import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import { ctx } from '@vl/redata';

export const LoadingCheckWrapper = ({ children, className = 'loader' }) => {
  return (
    <DIV className={ className }>
      {ctx.apply('loadingModel.isLoading') ? null : children }
    </DIV>
  );
};

export default LoadingCheckWrapper;
