import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import { useFormik } from 'formik';
import _ from 'lodash';
import AssetModelFormatter from '@uz/unitz-models/AssetModel/formatter';
import useRoute from '@vl/hooks/useGbRouteDe';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import usePromiseSource from '@vl/hooks/usePromiseSource';
import holidays from '@vl/mod-utils/holidays';
import { notification } from 'antd';

const bindData = bindings({
  loader: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchData: true }))),

            getAccountZone: hook((ctx) => {
              const route = useRoute();
              const routeParams = route.getParams();
              const account_profile_id = _.get(routeParams, 'account_profile.id', '');
              const data = usePromiseSource(
                async () => {
                  try {
                    if (!account_profile_id) return null;
                    const client = await getClient();
                    const data = await client.request(
                      gql`
                        query GetAccountZone($id: uuid!) {
                          b2b_account_profile_by_pk(id: $id) {
                            holiday_zone
                          }
                        }
                      `,
                      { id: account_profile_id }
                    );
                    ctx.apply('loadingModel.clearLoading', 'fetchData');
                    return data;
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                [account_profile_id]
              );

              return _.get(data, 'b2b_account_profile_by_pk', '');
            }),
          },
        },
      ],
    ],
  },
  component: {
    rules: [
      [
        'data',
        {
          data: {
            form: hook((ctx) => {
              const route = useRoute();
              const routeParams = route.getParams();
              const account_id = ctx.apply('accountModel.getAccountId');
              const Yup = ctx.get('ValidateHandler.Yup');

              const holidayData = JSON.parse(ctx.get('getAccountZone.holiday_zone'));

              const form = useFormik({
                initialValues: {
                  id: account_id,
                  name: _.get(routeParams, 'account_profile.display_name', ''),
                  avatar_url: !_.get(routeParams, 'account_profile.avatar_url', '')
                    ? ''
                    : [
                        {
                          url: _.get(routeParams, 'account_profile.avatar_url', ''),
                          status: 'success',
                        },
                      ],
                  holiday_zone: _.get(holidayData, 'country', ''),
                  holiday_state: _.get(holidayData, 'state', ''),
                  holiday_region: _.get(holidayData, 'city', ''),
                },
                onSubmit: async (values, actions) => {
                  try {
                    const payload = {
                      display_name: values.name,
                      avatar_url: _.get(values, 'avatar_url.0.url'),
                      holiday_zone:
                        _.get(values, 'holiday_zone', '') === ''
                          ? null
                          : JSON.stringify({
                              country: _.get(values, 'holiday_zone', ''),
                              state: _.get(values, 'holiday_state', ''),
                              city: _.get(values, 'holiday_region', ''),
                            }),
                    };

                    const client = await getClient();
                    const res = await client.request(
                      gql`
                        mutation UpdateAccountProfile(
                          $where: b2b_account_profile_bool_exp!
                          $_set: b2b_account_profile_set_input = {}
                        ) {
                          update_b2b_account_profile(where: $where, _set: $_set) {
                            returning {
                              id
                              avatar_url
                              display_name
                            }
                          }
                        }
                      `,
                      { where: { account_id: { _eq: account_id } }, _set: payload }
                    );

                    if (res) {
                      await notification.success({
                        placement: 'bottomRight',
                        message: ctx.apply('i18n.t', 'ZoomToolSettings.Notification.success'),
                      });
                    }
                  } catch (err) {
                    console.log(err);
                  }
                },
                validationSchema: Yup.object().shape({
                  name: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                }),
              });

              React.useEffect(() => {
                if (!holidays.getStates(form.values.holiday_zone) && !!form.values.holiday_state)
                  form.setFieldValue('holiday_state', '');
                if (
                  !holidays.getRegions(form.values.holiday_zone, form.values.holiday_state) &&
                  !!form.values.holiday_region
                )
                  form.setFieldValue('holiday_region', '');
              }, [form.values.holiday_zone, form.values.holiday_state, form.values.holiday_region]);

              _.assign(form, {
                canSubmit: form.isValid,
                holidayData: ctx.get('getAccountZone.holiday_zone'),
                getUploaderProps: AssetModelFormatter.getUploaderProps(ctx, form),
              });
              return form;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
