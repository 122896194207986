import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import _ from 'lodash';
import Image from '@uz/unitz-components-web/Image';
import Button from '@uz/unitz-components-web/Button';
import InputText from '@uz/unitz-components-web/InputText';
import { Form, FormItem, Select, Cascader } from 'formik-antd';

import { Upload, Progress } from 'antd';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import CustomInput from '@uz/unitz-components-web/CustomInput';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Row, Col } from 'antd';
import holidays from '@vl/mod-utils/holidays';
import LoadingCheckWrapper from '@uz/unitz-components-web/LoadingCheckWrapper';

const CoverPhotoInput = styled(Upload.Dragger)`
  .ant-upload.ant-upload-btn {
    padding: 0px;
  }
`;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  layout: 'vertical',
};

const Index = () => {
  // @TODO: validate missing required input
  return (
    <LoadingCheckWrapper>
      <DIV className="component">
        <DIV forceCtx>
          <FormProvider form={ctx.get('form')}>
            <Form {...layout} className="account-edit-form">
              <div className="mb-4 p-6 bg-white500 rounded-lg shadow-md">
                <FormItem
                  name="name"
                  label={
                    <span className="text-sm font-semibold text-sub">
                      {ctx.apply('i18n.t', 'ZoomToolAccount.Form.organization_name')}
                    </span>
                  }
                  required
                >
                  <CustomInput>
                    <InputText name="name" size="medium" />
                  </CustomInput>
                </FormItem>
                <FormItem
                  name="id"
                  label={
                    <span className="text-sm font-semibold text-sub">
                      {ctx.apply('i18n.t', 'ZoomToolAccount.Form.organization_id')}
                    </span>
                  }
                  required
                >
                  <CustomInput>
                    <InputText name="id" size="medium" disabled />
                  </CustomInput>
                </FormItem>
                <FormItem
                  name="avatar_url"
                  label={
                    <span className="text-sm font-semibold text-sub">
                      {ctx.apply('i18n.t', 'ZoomToolAccount.Form.logo')}
                    </span>
                  }
                >
                  <CustomInput>
                    <div className="relative">
                      <CoverPhotoInput
                        {...ctx.apply('form.getUploaderProps', { fieldName: 'avatar_url', multiple: false })}
                        itemRender={() => null}
                      >
                        {!ctx.get('form.values.avatar_url.0') ? (
                          <div className="flex flex-col items-center justify-center py-6">
                            <div className="mb-2 text-center ant-upload-text">
                              {gstyles.icons({ name: 'photo-library', size: 24, fill: gstyles.colors.sub })}
                            </div>
                            <Button style={{ borderColor: gstyles.colors.border }} name="ink">
                              <span>{ctx.apply('i18n.t', 'ZoomToolAccount.Form.add_logo')}</span>
                            </Button>
                            <div className="text-xs my-2 font-normal ant-upload-hint text-sub">
                              {ctx.apply('i18n.t', 'ZoomToolAccount.Form.add_logo')}
                            </div>
                          </div>
                        ) : (
                          <div
                            className="w-full"
                            //  className="flex items-center justify-center"
                          >
                            <Image
                              alt="photo"
                              src={ctx.get('form.values.avatar_url.0.url')}
                              // className="overflow-hidden rounded-lg"
                              // width={364}
                              // height={205}
                              style={{ height: 205, objectFit: 'contain' }}
                              size="cover"
                            />
                          </div>
                        )}
                      </CoverPhotoInput>
                      {ctx.get('form.values.avatar_url.0') && (
                        <div className="absolute top-0 right-0">
                          <Button
                            type="link"
                            name="ink"
                            onClick={() => ctx.apply('form.setFieldValue', 'avatar_url', [])}
                          >
                            <span>{gstyles.icons({ name: 'delete', size: 16, fill: gstyles.colors.sub })}</span>
                          </Button>
                        </div>
                      )}
                      {ctx.get('form.values.avatar_url.0.status') === 'uploading' && (
                        <div className="absolute inset-0 flex items-center justify-center p-4 bg-black opacity-50">
                          <Progress width={80} type="circle" percent={ctx.get('form.values.avatar_url.0.percent')} />
                        </div>
                      )}
                    </div>
                  </CustomInput>
                </FormItem>
              </div>
              <div className="bg-white500 shadow-md rounded-lg w-full">
                <div className="p-6">
                  <div className="text-main font-semibold text-base">
                    {ctx.apply('i18n.t', 'ZoomToolAccount.Form.holiday_title')}
                  </div>
                </div>
                <div className="border-b" />
                <div className="p-6 flex w-full flex-col">
                  <div className="text-sub font-normal text-sm mb-4">
                    {ctx.apply('i18n.t', 'ZoomToolAccount.Form.holiday_description')}
                  </div>
                  <Row gutter={[24, 24]}>
                    <Col span={8}>
                      <CustomInput>
                        <FormItem
                          name={'holiday_zone'}
                          label={
                            <div className="text-sub text-sm font-semibold">
                              {ctx.apply('i18n.t', 'ZoomToolAccount.Form.country')}
                            </div>
                          }
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                              option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            size="large"
                            name={'holiday_zone'}
                            suffixIcon={gstyles.icons({
                              name: 'arrow-down3',
                              size: 24,
                              fill: gstyles.colors.sub,
                              className: '-mt-2 -ml-2',
                            })}
                            placeholder={ctx.apply('i18n.t', 'ZoomToolCourse.Form.start_date_placeholder')}
                          >
                            <Select.Option value={''} key={'null'}>
                              {ctx.apply('i18n.t', 'Biz.Form.country_placeholder')}
                            </Select.Option>
                            {_.map(holidays.getCountries(), (item, index) => (
                              <Select.Option value={index} key={index}>
                                {item}
                              </Select.Option>
                            ))}
                          </Select>
                        </FormItem>
                      </CustomInput>
                    </Col>
                    {holidays.getStates(ctx.get('form.values.holiday_zone')) && (
                      <Col span={8}>
                        <CustomInput>
                          <FormItem
                            name={'holiday_state'}
                            label={
                              <div className="text-sub text-sm font-semibold">
                                {ctx.apply('i18n.t', 'ZoomToolAccount.Form.state')}
                              </div>
                            }
                          >
                            <Select
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              size="large"
                              name={'holiday_state'}
                              suffixIcon={gstyles.icons({
                                name: 'arrow-down3',
                                size: 24,
                                fill: gstyles.colors.sub,
                                className: '-mt-2 -ml-2',
                              })}
                              placeholder={ctx.apply('i18n.t', 'ZoomToolCourse.Form.start_date_placeholder')}
                            >
                              {_.map(holidays.getStates(ctx.get('form.values.holiday_zone')), (item, index) => (
                                <Select.Option value={index} key={index}>
                                  {item}
                                </Select.Option>
                              ))}
                            </Select>
                          </FormItem>
                        </CustomInput>
                      </Col>
                    )}

                    {holidays.getRegions(ctx.get('form.values.holiday_zone'), ctx.get('form.values.holiday_state')) && (
                      <Col span={8}>
                        <CustomInput>
                          <FormItem
                            name={'holiday_region'}
                            label={
                              <div className="text-sub text-sm font-semibold">
                                {ctx.apply('i18n.t', 'ZoomToolAccount.Form.city')}
                              </div>
                            }
                          >
                            <Select
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              size="large"
                              name={'holiday_region'}
                              suffixIcon={gstyles.icons({
                                name: 'arrow-down3',
                                size: 24,
                                fill: gstyles.colors.sub,
                                className: '-mt-2 -ml-2',
                              })}
                              placeholder={ctx.apply('i18n.t', 'ZoomToolCourse.Form.start_date_placeholder')}
                            >
                              {_.map(
                                holidays.getRegions(
                                  ctx.get('form.values.holiday_zone'),
                                  ctx.get('form.values.holiday_state')
                                ),
                                (region, index_g) => (
                                  <Select.Option value={index_g} key={index_g}>
                                    {region}
                                  </Select.Option>
                                )
                              )}
                            </Select>
                          </FormItem>
                        </CustomInput>
                      </Col>
                    )}
                  </Row>
                </div>
              </div>
              <div className="my-4 p-6 bg-white500 rounded-lg shadow-md">
                <Row justify="end">
                  <Col span={4}>
                    <Button onClick={ctx.get('form.submitForm')} type="primary" size="medium" block>
                      <span className="capitalize">
                        {_.lowerCase(ctx.apply('i18n.t', 'ZoomToolAccount.Action.save'))}
                      </span>
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </FormProvider>
        </DIV>
      </DIV>
    </LoadingCheckWrapper>
  );
};

export default Index;
