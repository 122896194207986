import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import useRoute from '@vl/hooks/useGbRouteDe';
import UserModel from '@uz/unitz-models/UserModel';
import useObservableSource from '@vl/hooks/useObservableSource';
import useSubjectSource from '@vl/hooks/useSubjectSource';

const bindData = bindings({
  ZoomToolOrganaztionMenu: {
    rules: [
      [
        'data',
        {
          data: {
            advisorProfileMenu: hook((ctx) => {
              const route = useRoute();
              const hash = _.get(route, 'location.hash', '');
              const menuKeys = _.compact(_.trim(_.get(route, 'location.pathname', '')).split('/'));

              const menuData = _.compact([
                {
                  href: ctx.apply('routeStore.toUrl', 'toolAccountEdit'),
                  id: 'edit',
                  text: 'Organization Information',
                  icon: 'home-work',
                  show: true,
                },
                {
                  href: ctx.apply('routeStore.toUrl', 'toolAccountEdit'),
                  id: 'subscription',
                  text: 'Subscription',
                  icon: 'shopping-cart',
                  show: true,
                },
                {
                  href: ctx.apply('routeStore.toUrl', 'toolAccountEdit'),
                  id: 'schools',
                  text: 'Schools',
                  icon: 'education',
                  show: true,
                },
                {
                  href: ctx.apply('routeStore.toUrl', 'toolAccountEdit'),
                  id: 'usage',
                  text: 'Usage',
                  icon: 'pie-chart',
                  show: true,
                },
                {
                  href: ctx.apply('routeStore.toUrl', 'toolAccountEdit'),
                  id: 'statistics',
                  text: 'Statistics',
                  icon: 'bar-chart',
                  show: true,
                },
              ]);
              const menuDataById = _.keyBy(menuData, 'id');

              const [activeMenu, $activeMenu] = React.useState(() => {
                return (
                  _.find(menuKeys, (key) => {
                    return _.get(menuDataById, key);
                  }) || 'home'
                );
              });

              const triggerClickMenu = (menuId) => {
                if (!menuId) return;
                const element = document.querySelector(menuId);
                if (element) {
                  element.click();
                }
              };

              React.useEffect(() => {
                if (hash) {
                  const menu = hash.replace('section', 'menu');
                  triggerClickMenu(menu);
                }
              }, []);

              return {
                menuData,
                activeMenu,
                onSelectMenu: (itemSelected) => {
                  $activeMenu(itemSelected.key);
                },
              };
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;
