import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import { UnAuthRedirect } from '@uz/unitz-components-web/AppRedirect';
import FormEditAccount from '@uz/unitz-tool-components/FormEditAccount';

const Index = () => {
  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <div className="p-4">
          <div className="mb-3 text-main uppercase font-semibold text-base">
            {ctx.apply('i18n.t', 'ZoomToolAccount.edit_organization')}
          </div>
          <FormEditAccount />
        </div>
      </UnAuthRedirect>
    </DIV>
  );
};

export default Index;
